import React from "react"
import createTemplate from "../../create-template"

import { useComponents } from "../../components"

function Quincy({ styles, elements, ...props }) {
  const Elements = useComponents()
  const customFieldCount = elements.custom.length
  const stackedDisplay = customFieldCount > 0
  const checkboxesColor = styles.forElement("field").color

  const linkDefaults = {
    settings: {
      radius: "0",
      shadow: "none",
      columns: 2,
      backgroundColor: "#2B2224",
      textColor: "#ffffff",
      iconBorder: "0",
      iconBorderColor: "#ffffff",
      fontSize: "16",
      fontWeight: "bold",
      iconColorStyle: "platform",
      iconColor: "#58ab7e",
      linkBorder: "3px",
      linkBorderColor: "#ffffff"
    },
    children: [
      {
        type: "Link",
        url: "https://www.etsy.com",
        text: "My shop",
        icon: "etsy",
        hasIcon: "social"
      },
      {
        type: "Link",
        url: "https://www.instagram.com",
        text: "Follow me on Instagram",
        icon: "instagram",
        hasIcon: "social"
      },
      {
        type: "Link",
        url: "https://www.pinterest.com",
        text: "My favorite pins",
        icon: "pinterest-p",
        hasIcon: "social"
      },
      {
        type: "Link",
        url: "https://www.snapchat.com",
        text: "Watch my stories",
        icon: "snapchat-ghost",
        hasIcon: "social"
      }
    ]
  }

  return (
    <Elements.LandingPage
      styles={styles}
      {...props}
      style={styles.forElement("page")}
    >
      <div
        style={{
          backgroundColor: styles.forElement("background").backgroundColor
        }}
        className="formkit-bg-color"
      />
      <div
        style={styles.forElement("background")}
        className="formkit-bg-image"
      />
      <div className="formkit-container">
        <header className="formkit-content-container">
          <Elements.Heading
            className="formkit-heading"
            name="heading"
            defaults={{
              content: "Daniella Rivera"
            }}
          />
        </header>
        <main>
          <div className="formkit-content-container">
            <Elements.Region className="formkit-content" name="content">
              <Elements.Content
                defaults={{
                  content:
                    "<b>Pastry chef, author & founder.</b><br/>Making the world a sweeter place with my world-famous doughnuts & cookies."
                }}
              />
            </Elements.Region>
            <Elements.Content
              className="formkit-form-heading"
              name="form_heading"
              defaults={{
                content: "Sign up to the Weekly Sweets newsletter."
              }}
            />
            <Elements.Form>
              <Elements.Errors />
              <Elements.CustomFields
                style={{
                  color: checkboxesColor
                }}
                data-stacked={stackedDisplay}
              >
                {stackedDisplay && <Elements.AddFieldButton />}
                <Elements.Button
                  name="button"
                  group="button"
                  defaults={{ content: "SIGN UP" }}
                />
              </Elements.CustomFields>
              {!stackedDisplay && <Elements.AddFieldButton />}
            </Elements.Form>
            <Elements.Content
              className="formkit-disclaimer"
              name="disclaimer"
              defaults={{
                content: "I won’t send you spam. Unsubscribe anytime."
              }}
            />
          </div>
          <Elements.Region
            className="formkit-collection-container"
            name="collection_content"
            group="collection_content"
          >
            <Elements.Content
              defaults={{
                content: "Find me & my shop here"
              }}
            />
            <Elements.Links
              defaults={linkDefaults.settings}
              defaultChildren={linkDefaults.children}
            />
          </Elements.Region>
        </main>
      </div>
      <Elements.BuiltWith background="background" image="background" />
    </Elements.LandingPage>
  )
}

export default createTemplate(Quincy, { name: "Quincy" })
